import { h } from "preact";
import { Link } from "preact-router/match";
import style from "./style.css";

const Header = () => (
  <div class={style.header}>
   <a href="#inicio" class={style.logo}>
      <img src="../../assets/solecnegro.svg" height="100" width="200" />
    </a>

    <div class={style.addressContainer}>
      <div class={style.addressItem}>
        <a href="https://wa.me/5213329641629?text=Hablar%20con%20un%20agente" target="_blank"><img src="../../assets/whatsapp.png" height="23" width="23" /></a>
        <a href="https://wa.me/5213329641629?text=Hablar%20con%20un%20agente" target="_blank"><p class={style.address}>Hablar con un Agente : 3329641629</p></a>
      </div>

      <div class={style.addressItem}>
        <img src="../../assets/mail.png" height="25" width="25" />
        <p class={style.address}>info@solec.mx</p>
      </div>
      <div class={style.addressItem}>
        <a href="https://www.facebook.com/solecmx" target="_blank">
          <img src="../../assets/facebook.png" height="25" width="25" />
        </a>
        <a href="https://www.facebook.com/solecmx" target="_blank">
          <p class={style.address}>solecmx</p>
        </a>
      </div>
    </div>

    <div class={style.addressContainer}>
      <div class={style.addressItem}>
        <img src="../../assets/concepto.png" height="25" width="25" />
        <p class={style.address}>Eficiencia Económica Integral</p>
      </div>
      <div class={style.addressItem}>
        <img src="../../assets/reloj.png" height="23" width="23" />
        <p class={style.address}>10:00 AM – 6:00 PM </p>
      </div>
      <div class={style.addressItem}>
        <img src="../../assets/location.png" height="25" width="25" />
        <p class={style.address}>
          San jóse 127, Santa Isabel. Tlajomulco de Zúñiga.
        </p>
      </div>
    </div>
  </div>
);

export default Header;
